import { inject, Injectable } from '@angular/core';
import { GlobalStore } from '../../store/global.store';
import { SuedoUser } from '../../interfaces/suedo-user.interface';

export enum ACCESS_ID {
  SHOW_HISTORICAL_DATA = 'show-historical-data',
  SHOW_USER_MANAGEMENT = 'show-user-management',
  SHOW_OFFERS = 'sales-offers',
  SHOW_ORDERS = 'sales-orders',
  SHOW_CREDIT_NOTES = 'sales-credit-notes',
  SHOW_BACKORDER = 'backlog',
}

export enum CRUD_METHOD {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
  READ = 'read',
}

@Injectable({ providedIn: 'root' })
export class AccessService {
  store = inject(GlobalStore);

  public hasAccess(accessId: string | boolean | undefined, user: SuedoUser | null): boolean {
    if (accessId === undefined) {
      return true;
    }

    if (!user) {
      return false;
    }

    if (typeof accessId !== 'string') {
      return !!accessId
    }

    return this.isAuthorized(accessId, user);
  }

  isAuthorized(accessId: string, user: SuedoUser, method: CRUD_METHOD = CRUD_METHOD.READ): boolean {
    if (accessId === ACCESS_ID.SHOW_HISTORICAL_DATA) {
      return user.oP48APIAuthorizationGroup.shtShowOpenItems === 1;
    }

    if (accessId === ACCESS_ID.SHOW_USER_MANAGEMENT) {
      if (user.oP48APIAuthorizationGroup.lngAPIAuthorizationGroupID !== null) {
        return user.oP48APIAuthorizationGroup.shtP48ShowUserManagement === 1;
      } else {
        return user.shtP48ShowUserManagement === 1;
      }
    }

    const endpoint = user.oP48APIAuthorizationGroup?.oP48APIAuthorizationGroupAssignList.find(
      (agl) => agl.sAPIAuthorizationID === accessId,
    );

    if (endpoint) {
      switch (method) {
        case CRUD_METHOD.CREATE:
          return endpoint.bCRUDCreate === 1;
        case CRUD_METHOD.UPDATE:
          return endpoint.bCRUDUpdate === 1;
        case CRUD_METHOD.DELETE:
          return endpoint.bCRUDDelete === 1;
        case CRUD_METHOD.READ:
          return endpoint.bCRUDRead === 1;
      }
    }

    return false;
  }
}
